export const getStatus = (statusCode = null) => {
  switch (statusCode) {
    case 0:
      return 'PENDING';
    case 1:
      return 'INITIATED';
    case 2:
      return 'SUCCESS';
    case 3:
      return 'FAILED';
    case 4:
      return 'CANCELLED';
    default:
      return 'PENDING';
  }
};
